@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
/*variable.css*/
* {
    --color-focus: #BE1622;
    --color-focus-secondary: #000001;
    --colot-gray-600: #8F9BB3;
    --page-width: 100%;
}

/*theme.css*/
* {
    box-sizing: border-box;
    margin: 0;
}

/* ---------------------------------------------------
    CONTAINERS
----------------------------------------------------- */


body {
    background-color: #EDF1F7 !important;
    min-width: 320px;
}

#container {
    width: var(--page-width);
}


/* ---------------------------------------------------
    FONTS
----------------------------------------------------- */

@font-face {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/Montserrat-Regular.3cd78665.ttf)
}

@font-face {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    src: url(/static/media/Montserrat-Medium.e2d60bc4.ttf)
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    src: url(/static/media/Montserrat-SemiBold.fa8441f3.ttf)
}

@font-face {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    src: url(/static/media/Montserrat-Bold.079ca05d.ttf)
}

/* ---------------------------------------------------
    CONTENT
----------------------------------------------------- */

.h1 {
    /* H1 - site */
    font-family: Montserrat-SemiBold;
    font-size: 36px;
    line-height: 52px;
    /* or 144% */
    display: flex;
    align-items: center;
}

.h1.medium {
    font-family: Montserrat-Medium;
}

.h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 32px;
    /* identical to box height, or 160% */
    /* gray / white */
    color: #FFFFFF;
}

.p1 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
}

.p2 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    /* gray / white */
    color: #FFFFFF;
}

th,
.th-action {
    background-color: #1E54A5 !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    color: white;
}

.table-responsive.react-bootstrap-table .td-action,
.table-responsive.react-bootstrap-table .th-action {
    position: inherit !important;
}

.react-bootstrap-table {
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 2px 7px 10px rgba(33, 33, 33, 0.05);
    transition: 1s;
}

.react-bootstrap-table:hover {
    box-shadow: 2px 7px 10px rgba(33, 33, 33, 0.1);
}

td,
.td-action {
    background-color: rgba(255, 255, 255, 0.3) !important;
}


.animated-ellipsis {
    text-align: left;
}

.animated-ellipsis:after {
    width: 0.875em;
    overflow: visible;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(6, end) 900ms infinite;
    content: "...";
}

@keyframes ellipsis {

    0%,
    100% {
        content: ".";
    }
    
    25%,
    75% {
        content: "..";
    }

    50% {
        content: "...";
    }
}

/* ---------------------------------------------------
    INPUTS
----------------------------------------------------- */

.app-input-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 5px 0px;
}

input.app-input {
    margin: 0px 0px 20px 0px;
    padding: 6px 6px 6px 8px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #8F9BB3;
    box-sizing: border-box;
}

input.app-input:focus {
    border: 1px solid #0097FF !important;
    box-sizing: border-box;
    outline: none;
}

input.app-input::placeholder {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--colot-gray-600);
}

.app-input-wrapper span.app-input-validation-msg {
    color: var(--color-focus);
    margin-left: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: -20px;
}

.input-icon-wrapper {
    position: relative;
}

.input-icon-wrapper input.app-input {
    padding: 6px 35px 6px 8px;
}

.input-icon {
    position: absolute;
    top: 8px;
    right: 25px;
}

input[type="checkbox"] {
    position: relative;
    width: 1em;
    height: 1em;
    color: black;
    border: 1px solid #1E54A5;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

input[type="checkbox"]::before {
    position: absolute;
    content: '';
    display: block;
    top: 0.05em;
    left: 0.25em;
    width: 0.3em;
    height: 0.6em;
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}

input[type="checkbox"]:checked {
    color: white;
    border-color: #000001;
    background: #000001;
}

input[type="checkbox"]:checked::before {
    opacity: 1;
}

input[type="checkbox"]:disabled {
    position: relative;
    width: 1em;
    height: 1em;
    color: gray;
    border: 1px solid gray;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

input[type="checkbox"]:disabled::before {
    position: absolute;
    content: '';
    display: block;
    top: 0.05em;
    left: 0.25em;
    width: 0.3em;
    height: 0.6em;
    border-style: solid;
    border-color: gray;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}

input[type="checkbox"]:disabled:checked {
    color: white;
    border-color: lightgray;
    background: lightgray;
}

input[type="checkbox"]:disabled:checked::before {
    opacity: 1;
}

input[type="checkbox"]:disabled:hover {
    cursor: not-allowed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* ---------------------------------------------------
    ICONES
----------------------------------------------------- */


.icon {
    width: 20px;
    height: 20px;
    /* margin-top: 5px; */
    display: inline-block;
    vertical-align: -0.325em;
}

.icon-search {
    background-color: black;
    /* defines the background color of the image */
    mask: url(/static/media/saearch-icon.b5cf9bd3.svg) no-repeat center / contain;
    -webkit-mask: url(/static/media/saearch-icon.b5cf9bd3.svg) no-repeat center / contain;
}

.icon-arrow-left {
    background-color: black;
    /* defines the background color of the image */
    mask: url(/static/media/arrow-left-icon.e3543f12.svg) no-repeat center / contain;
    -webkit-mask: url(/static/media/arrow-left-icon.e3543f12.svg) no-repeat center / contain;
}

.icon-display-list {
    background-color: black;
    /* defines the background color of the image */
    mask: url(/static/media/list-display-icon.b90abd48.svg) no-repeat center / contain;
    -webkit-mask: url(/static/media/list-display-icon.b90abd48.svg) no-repeat center / contain;
}

.icon-display-card {
    background-color: black;
    /* defines the background color of the image */
    mask: url(/static/media/card-display-icon.82b97cec.svg) no-repeat center / contain;
    -webkit-mask: url(/static/media/card-display-icon.82b97cec.svg) no-repeat center / contain;
}


.app-navbar {
    width: 100%;
}

.app-navbar input {
    color: #FFFFFF;
}

.app-navbar input::placeholder {
    color: #FFFFFF;
}

.app-navbar .icon {
    background-color: #FFFFFF;
}

.app-navbar input.app-input-search {
    background: rgba(255, 255, 255, 0.2);
    border: none;
}

/* ---------------------------------------------------
    TABS
----------------------------------------------------- */

.app-tab-wrapper {
    display: flex;
    flex-direction: row !important;
    justify-content: left !important;
    align-items: center !important;
    flex-wrap: wrap;
}

.app-tab {
    margin: 10px 20px 10px 0px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #8F9BB3;
    white-space: nowrap;
    cursor: pointer;
    /* text-shadow: 0px 20px 4px rgba(33, 33, 33, 0.25); */
}

.app-tab-disabled,
.app-tab-disabled:hover {
    cursor: default !important;
    color: #8F9BB3 !important;
    text-shadow: none !important;
    text-decoration: none !important;
}

.app-tab:hover {
    color: #000001;
    text-shadow: 0px 7px 4px rgba(33, 33, 33, 0.25);
    text-decoration: none;
}

.app-tab:focus {
    color: #000001;
    text-shadow: 0px 7px 4px rgba(33, 33, 33, 0.25);
    text-decoration: underline;
    outline: none;
}

.app-tab-active {
    font-weight: bold;
    color: #000001;
}

.app-tab-btn {
    border: 1px solid #C5CEE0;
    border-radius: 25px;
    height: 48px;
    width: 48px;
}

.app-tab-btn:hover {
    box-shadow: 0px 7px 4px rgba(33, 33, 33, 0.25);
}

.app-tab-btn .icon {
    margin-left: 13px;
}

.app-tab-btn:hover .icon {
    background-color: #000001;
}

.app-tab-btn:focus {
    border: 1px solid #000001;
    box-shadow: 0px 7px 4px rgba(33, 33, 33, 0.25);
}

/* ---------------------------------------------------
    BUTTONS
----------------------------------------------------- */

.app-btn {
    border-radius: 4px;
    text-align: center;
    padding: 10px 20px;
    border: none;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.app-btn-xs {
    height: 40px;
}

.app-btn:hover,
.app-btn:focus {
    border: 1px solid #0097FF;
    padding: 09px 19px;
    outline: none;
}

.app-btn:active {
    border: 1px solid black;
    box-shadow: 0px 20px 24px rgba(33, 33, 33, 0.25);
    /* padding: 09px 19px; */
    /* outline: none; */
}

.app-btn-focus {
    color: #FFFFFF;
    background: var(--color-focus-secondary);
}

.app-btn-focus:hover,
.app-btn-focus:focus {
    color: #FFFFFF;
    box-shadow: 0px 20px 24px rgba(33, 33, 33, 0.25);
}

/* ---------------------------------------------------
    PAGES 
----------------------------------------------------- */

.app-page-wrapper {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.app-page-content {
    max-width: 1056px;
    /* -----------------------------------------
    Calcula um espço para desconsidera a lagura da barra
    de rolagem da pagina que pode afetar o posicionamento 
    do elmento caso estea visível
    -------------------------------------------- */
    /* margin-left: calc(100vw - 100% - 10vw); */

}

.app-page-content-size-small {
    width: calc(100% /12 * 4);
}

.app-page-content-size-medium {
    width: calc(100% /12 * 8);
}

.app-page-content-size-large {
    width: calc(100% /12 * 12);
}

.app-page-return-btn {
    border: none;
    background-color: inherit;
    color: #1E54A5;
    cursor: pointer;
    outline: none;
    margin-bottom: 5px;
    padding-left: 0px;
}

.app-page-return-btn:hover,
.app-page-return-btn:focus {
    text-decoration: underline;
    outline: none;
}

.app-page-return-btn:active {
    box-shadow: 0px 20px 24px rgba(33, 33, 33, 0.25);
    outline: none;
}

.app-page-return-btn .icon {
    margin-right: 5px;
    background-color: #1E54A5;
}

/* ---------------------------------------------------
    COLUMNS
----------------------------------------------------- */

.app-col-1 {
    width: calc(100% /12 * 1);
}

.app-col-2 {
    width: calc(100% /12 * 2);
}

.app-col-3 {
    width: calc(100% /12 * 3);
}

.app-col-4 {
    width: calc(100% /12 * 4);
}

.app-col-4 {
    width: calc(100% /12 * 5);
}

.app-col-6 {
    width: calc(100% /12 * 6);
}

.app-col-7 {
    width: calc(100% /12 * 7);
}

.app-col-8 {
    width: calc(100% /12 * 8);
}

.app-col-9 {
    width: calc(100% /12 * 9);
}

.app-col-10 {
    width: calc(100% /12 * 10);
}

.app-col-11 {
    width: calc(100% /12 * 11);
}

.app-col-12 {
    width: calc(100% /12 * 12);
}

.page-link {
    color: #1E54A5;
}

.page-item.active .page-link {
    background-color: #000001;
    border-color: #000001;
}

.btn-secondary {
    background-color: #1E54A5;
    border-color: #1E54A5;
}

/* ---------------------------------------------------
    SCROLLBAR
----------------------------------------------------- */

/* width */
::-webkit-scrollbar {
    width: 0.5vw;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    -webkit-transition: 10s;
    transition: 10s;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.8);
}

::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.4);
}

#sidebar {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

#sidebar::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

/* ---------------------------------------------------
    OTHERS
----------------------------------------------------- */

.react-datepicker__current-month {
    text-transform: capitalize;
    color: white !important;
}

.react-datepicker__header {
    background-color: #1E54A5 !important;
}

.react-datepicker__day {
    transition: 0.3s;
}

.react-datepicker__day:hover {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:active,
.react-datepicker__day--selected:focus {
    background-color: #000001 !important;
    outline: none;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:active,
.react-datepicker__day--keyboard-selected:focus {
    background-color: #ffd6d9 !important;
    color: black !important;
    outline: none;
}

.react-datepicker {
    border: transparent;
    border-radius: 15px !important;
}

.react-datepicker__day-name {
    font-feature-settings: "smcp";
    font-variant: small-caps;
    color: white !important;
}

.react-datepicker__month-read-view--selected-month {
    color: white !important;
}

.react-datepicker__year-read-view--selected-year {
    color: white !important;
}

.react-datepicker-year-header {
    color: white !important;
}

.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--selected {
    background-color: #000001 !important;
}
